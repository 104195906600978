const theme = {
  colors: {
    white: 'hsla(0, 0%, 100%, 1)',
    lynxWhite: 'hsla(0, 0%, 97%, 1)',
    lynxWhite50: 'hsla(0, 0%, 97%, 0.4)',
    whiteSmoke: 'hsla(0, 0%, 93%, 1)',
    bastille: 'hsla(240, 2%, 20%, 1)',
    charcoal: 'hsla(0, 0%, 25%, 1)',
    charcoal50: 'hsla(0, 0%, 25%, 0.5)',
    summerSky: 'hsla(193, 63%, 54%, 1)',
    zircon: 'hsla(180, 3%, 92%, 1)',
    facebook: 'hsla(220, 46%, 48%, 1)',
    twitter: 'hsla(203, 89%, 53%, 1)',
    linkedin: 'hsla(213, 63%, 43%, 1)',
    instagram: 'hsla(326, 57%, 48%, 1)',
    silver: 'hsla(0, 0%, 80%, 1)',
  },
  fonts: {
    body: 'Open Sans',
    heading: 'Open Sans',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 500,
    mediumBold: 600,
    extraBold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  styles: {
    // add base, top-level styles to the <body> element
    root: {
      fontFamily: 'body',
      fontWeight: 'normal',
    },
    h1: {
      fontFamily: 'body',
      fontWeight: 'extraBold',
      lineHeight: '1.2em',
      color: 'charcoal',
      fontSize: 5,
    },
    h4: {
      fontFamily: 'body',
      fontWeight: 'extraBold',
      lineHeight: '1.2em',
      color: 'charcoal',
      fontSize: 4,
    },
    p: {
      fontFamily: 'body',
      fontWeight: 'light',
      color: 'charcoal',
      fontSize: 3,
    },
    // all bullets in a list are summerSky blue
    ul: {
      listStyle: 'none' /* Remove default bullets */,
      'li::before': {
        // eslint-disable-next-line quotes
        content: "'●'",
        color: 'summerSky',
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
      },
    },
    ol: {
      'li::marker': {
        color: 'charcoal',
        fontWeight: 'extraBold',
      },
    },
    li: {
      fontFamily: 'body',
      fontWeight: 'light',
      color: 'charcoal',
      fontSize: 3,
    },
    // use horizontal rule for spacing between paragraphs
    hr: {
      background: 'transparent',
      paddingY: 2,
    },
    a: {
      textDecoration: 'none',
      color: 'summerSky',
      '&:hover': {
        opacity: 0.8,
        transition: 'color .15s ease-out',
      },
    },
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 36, 56, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 150, 200, 250, 500],
  breakpoints: ['640px', '768px', '1025px', '1280px'],
  text: {
    default: {
      fontFamily: 'body',
      color: 'text',
      fontSize: 2,
    },
    heading: {
      fontFamily: 'body',
      fontWeight: 'extraBold',
      lineHeight: '1.5em',
      color: 'charcoal',
      fontSize: [5, 5, 6, 6],
    },
    priceHeading: {
      fontFamily: 'body',
      fontWeight: 'extraBold',
      lineHeight: '1.4em',
      color: 'charcoal',
      fontSize: 5,
    },
    priceDetail: {
      fontFamily: 'body',
      fontWeight: '600',
      lineHeight: '1.4em',
      color: 'charcoal',
      fontSize: 4,
    },
    linkHeader: {
      fontFamily: 'body',
      fontWeight: 'extraBold',
      color: 'charcoal',
      fontSize: 2,
    },
    detail: {
      fontFamily: 'body',
      fontWeight: 'light',
      color: 'charcoal',
      lineHeight: '1.6em',
      fontSize: 3,
    },
    detailBold: {
      fontFamily: 'body',
      fontWeight: 'bold',
      color: 'charcoal',
      lineHeight: '1.6em',
      fontSize: 3,
    },
  },
  buttons: {
    primary: {
      color: 'white',
      fontWeight: 'mediumBold',
      bg: 'summerSky',
      '&:hover': {
        opacity: 0.8,
        transition: 'color .15s ease-out',
      },
    },
    outlined: {
      color: 'summerSky',
      fontWeight: 'mediumBold',
      bg: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'summerSky',
      '&:hover': {
        opacity: 0.8,
        transition: 'color .15s ease-out',
      },
    },
  },
  forms: {
    label: {
      fontFamily: 'body',
      fontWeight: 'light',
      color: 'charcoal',
      fontSize: 3,
    },
    input: {
      borderColor: 'silver',
      backgroundColor: 'white',
    },
    textarea: {
      borderColor: 'silver',
      backgroundColor: 'white',
    },
  },
}

export default theme

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-financial-overview-js": () => import("./../../../src/pages/financial-overview.js" /* webpackChunkName: "component---src-pages-financial-overview-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-help-and-support-js": () => import("./../../../src/pages/help-and-support.js" /* webpackChunkName: "component---src-pages-help-and-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-js": () => import("./../../../src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-quotes-js": () => import("./../../../src/pages/quotes.js" /* webpackChunkName: "component---src-pages-quotes-js" */),
  "component---src-pages-scheduling-js": () => import("./../../../src/pages/scheduling.js" /* webpackChunkName: "component---src-pages-scheduling-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-test-icons-js": () => import("./../../../src/pages/test_icons.js" /* webpackChunkName: "component---src-pages-test-icons-js" */)
}

